<template>
  <div
    class="flix-carousel flix-slide"
    v-if="!close"
    :class="{ 'flix-closing': isClosing }"
  >
    <div class="flix-container-fluid">
      <div class="flix-row">
        <div class="flix-col-md-12">
          <span class="flix-pull-right" style="padding-top: 7px">
            <a href="#" class="flix-html-a" @click.prevent="setClose()">
              <small class="flix-html-small">{{
                $t('message.close', { name: '' })
              }}</small>
              <flixIcon style="font-size: 1.5em" id="remove" />
            </a>
          </span>
        </div>
      </div>
      <div class="flix-row">
        <div class="flix-col-md-6 flix-col-md-flix-offset-3">
          <div class="flix-carousel-inner">
            <div
              class="flix-item"
              :class="{ 'flix-active': index === step }"
              v-for="(slide, index) in slides"
              :key="index"
            >
              <div class="flix-html-h1">
                <flixIcon :id="slide.icon" />
              </div>
              <h3>{{ slide.title }}</h3>
              <p>
                <span v-html="replaceLink($nl2br(slide.content))"></span>
                <br /><br />
                <a
                  href="#"
                  @click.prevent="setSlide(-1)"
                  v-if="step > 0"
                  class="flix-btn flix-btn-default"
                  ><flixIcon id="chevron-left" />
                  <small class="flix-html-small">{{
                    $t('message.previous')
                  }}</small></a
                >
                <span class="flix-btn flix-btn-default flix-disabled"
                  >{{ step + 1 }}/{{ slides.length }}</span
                >
                <a
                  href="#"
                  @click.prevent="setSlide(1)"
                  v-if="step < slides.length - 1"
                  class="flix-btn flix-btn-default"
                  ><small class="flix-html-small">{{
                    $t('message.next')
                  }}</small>
                  <flixIcon id="chevron-right"
                /></a>
                <a
                  href="#"
                  @click.prevent="setClose()"
                  v-if="step == slides.length - 1"
                  class="flix-btn flix-btn-default"
                  ><small class="flix-html-small">{{
                    $t('message.close', { name: '' })
                  }}</small>
                  <flixIcon id="remove"
                /></a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {},
  mounted() {
    this.getCheckRegisterDate()
  },
  computed: {},
  data() {
    return {
      isClosing: false,
      variables: this.$getUserVariables(),
      close: !this.$store.state.messages.startScreen,
      step: 0,
      slides: this.$t('startSlides')
    }
  },
  methods: {
    getCheckRegisterDate() {
      if (typeof this.variables.user.registration === 'undefined') {
        this.close = true
        return false
      }
      var date = this.$createDate(this.variables.user.registration)
      date.setDate(date.getDate() + 1)

      if (date.getTime() < new Date().getTime()) {
        this.close = true
      }
    },
    replaceLink(text) {
      var Rexp =
        /(\b(https?|ftp|file):\/\/([-A-Z0-9+&@#%?=~_|!:,.;]*)([-A-Z0-9+&@#%?/=~_|!:,.;]*))/gi
      const lang = this.$i18n.locale === 'en' ? 'net' : this.$i18n.locale

      return text
        .replace(Rexp, "<a href='$1' title='$1' class='flix-html-a'>$4</a>")
        .split('href=\'https://bookingflix.' + lang + '/')
        .join('target="_blank" href=\'https://bookingflix.' + lang + '/')
        .split('href="https://bookingflix.' + lang + '/')
        .join('target="_blank" href="https://bookingflix.' + lang + '/')
        .split('href=\'https://bookingflix.com/')
        .join('href=\'/')
    },
    setClose() {
      this.isClosing = true

      setTimeout(
        function () {
          this.close = true
          this.$store.commit('closeStartScreen')
        }.bind(this),
        800
      )
    },
    setSlide(add) {
      this.step += add
    }
  }
}
</script>
<style lang="sass" scoped>
@keyframes closeMe
  from
    transition: scaleY(1)
  to
    transform: scaleY(0)
    opacity: 0.5
.flix-closing
  transform-origin: top center
  animation: 0.8s closeMe ease-in
.flix-carousel
  background-color: #eee
  box-sizing: border-box
  box-shadow: inset 0 0 60px -10px rgba(0,0,0,0.3)
  .flix-carousel-inner
    min-height: 300px
    display: flex
    align-items: center
    text-align: center
    padding: 50px 0px
    box-sizing: border-box
    .flix-item
      flex: 1
      word-wrap: break-word
      word-break: normal
      box-sizing: border-box
      a
        word-wrap: break-word
        word-break: break-all

  @media (max-width: 500px)
    .flix-carousel-inner
      padding: 10px 0px
</style>
